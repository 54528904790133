import React from 'react';
import { DispatchProp } from 'react-redux';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';

import { HomeProvider } from 'src/components/Home/HomeProvider';
import { DefaultMeta } from 'src/components/AppShell/Head';
import { HomeCarouselWrapper } from 'src/components/Home/HomeCarousel';
import { Container, Wrapper } from 'src/components/Layout';
import { PromotionsWrapper } from 'src/components/Home/Promotions';
import { NostoContextProvider } from 'src/components/Nosto/NostoProvider';
import { fireGTMEvent } from 'src/features/analytics/hooks/useGtm';
import { Nosto } from 'src/components/Nosto/Nosto';
import { ProductAddToBasketModal } from 'src/components/AtbModal/ProductAddToBasketModal';

import meta from './home.json';

import '../../scss/_root-component.scss';

type HomePageProps = {
    id: number;
    outdated: boolean;
    version: string | undefined;
} & DispatchProp<any>;

export const HomePage: React.FC<HomePageProps> = () => {
    useSetResolved();

    React.useEffect(() => {
        fireGTMEvent({ event: 'pageview', pageType: 'Home Page' });
    }, []);

    return (
        <NostoContextProvider
            nostoData={{
                type: ['addToBasket', 'front'],
            }}
            pageType="front"
        >
            <HomeProvider>
                <DefaultMeta data={meta.helmet} />
                <Wrapper variant="home" data-test-id="home-page">
                    <HomeCarouselWrapper />
                    <PromotionsWrapper />
                    <Container>
                        <Nosto type="front" />
                    </Container>
                </Wrapper>
            </HomeProvider>
            <ProductAddToBasketModal />
        </NostoContextProvider>
    );
};

export default HomePage;
